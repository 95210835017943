import { useEffect } from 'react';
import {CircleMarker} from 'react-leaflet';
import { useRecoilState, useRecoilValue } from 'recoil';
import {quarterDegree} from './grid.js';
import {gridpoints} from './context.js';

function* range(start,stop,step){
    while (start<=stop){
      yield start;
      start = start + step;
    }
  }

function common(a,b){
  return a.filter((x)=>(a.lat==b.lat && a.lng==b.lng))
}
export default function GridPoints(props){
    const invisible = {radius:1.5,opacity:0.0,fillOpacity:0.0}

    let sw = quarterDegree(props.bounds._southWest,Math.ceil)
    let ne = quarterDegree(props.bounds._northEast,Math.floor)
    const [era5points,setPoints] = useRecoilState(gridpoints)
    
    let points = [];
    
    const calcPoints = () =>{
      console.log(ne);
      console.log('""""""GRIDPOINTS""""""""""""""');
      
      if (true){
        for (let i of range(sw.lat,ne.lat,0.25)){
          for (let j of range(sw.lon,ne.lon,0.25)){
              points.push({lat:i,lng:j,options:invisible})
            }
        }
      }
      
    }
    //useEffect(()=>(setPoints(points)),[props.bounds])
    useEffect(()=>(calcPoints(),setPoints([...points])),[props.bounds])
    //calcPoints()
    //setPoints([...points])

    return(<> 
         {era5points.map((latlng,i)=>{return <CircleMarker key={i} center={latlng} 
            pathOptions={latlng.options} />})}
        </>)
    
}