import LassoControl from "leaflet-lasso";
import { useEffect,useState } from "react";
import { useMap } from "react-leaflet";
import L from 'leaflet';
import GridPoints  from './gridpoints.js'
import { useRecoilValue } from "recoil";
import {boundsContext} from './context.js';
import Snackbar from '@material-ui/core/Snackbar';

export default function Catch(props){
    const [open,setOpen] = useState(true)
    const bounds = useRecoilValue(boundsContext)
    const map = useMap()
    let tmp = null;
    useEffect(()=>{
        const tmp = L.control.lasso(props.options).addTo(map)},[map])
    // useEffect(()=>{
    //     console.log("***************************")
    //     //const tmp = L.control.lasso(props.options).addTo(map)
    //     if (map.getZoom()<6 && tmp){
    //         tmp.disable()
    //     }else{tmp.enable()}
    //     })
    return (<>
        {map.getZoom()>=6 ? <GridPoints  bounds={bounds} />:
        <Snackbar
            open={open}
            autoHideDuration={6000}
            message="Lasso is not active at this zoom level"
        />}
        </>)
}