import { Typography } from '@material-ui/core'
import { SettingsRemoteRounded } from '@material-ui/icons';
import { useEffect,useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {estimatedTime,startTimer} from './context.js'

export default function CountDown(props){
    const timeDisplay = (t) => {
        let years = Math.floor(t/(365*24*3600))
        let days = Math.floor((t-years*365*24*3600)/24/3600)
        let hours = Math.floor((t-years*365*24*3600-days*24*3600)/3600)
        let minutes = Math.floor((t-years*365*24*3600-days*24*3600-hours*3600)/60)
        let seconds = t-years*365*24*3600-days*24*3600-hours*3600-minutes*60
        return {year:years,day:days,hour:hours,minute:minutes,second:seconds}
    }
    
    let [startTimer_,setStart] = useRecoilState(startTimer)
    var initialTime = useRecoilValue(estimatedTime)
    let [time,setTime] = useState(initialTime)
    let [display,setDisplay] = useState(timeDisplay(time))

    
    useEffect(()=>setTime(initialTime),[initialTime])
    useEffect(() => {
        setDisplay(timeDisplay(time))
        console.log("UseEffect time *********'")

        if (time > 0 && startTimer_) {
            const timer = setTimeout(() => {
                    clearTimeout(timer)
                    setTime(time - 1);
            }
        , 1000)
        }else{
            setStart(false)
            setTime(initialTime)
        }
    }, [time,startTimer_]);

    return (<><Typography variant="body1">Expected time to retrieve data:</Typography>
            <Typography variant="body1"> {
                Object.keys(display).map(o=>display[o]?`${display[o]} ${o}${display[o]>1?'s':''} `:'')} </Typography></>
    )
}