import './App.css';
import {useEffect} from 'react'
import Form from './form';
import SelectMap from './map';
// import RecoilizeDebugger from "recoilize";
import {RecoilRoot, useRecoilValue} from 'recoil';
import {useRecoilSnapshot,useRecoilCallback} from 'recoil';

function DebugObserver(){
  const snapshot = useRecoilSnapshot();
  
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}
function DebugButton() {
  const onClick = useRecoilCallback(({snapshot}) => async () => {
    console.debug('Atom values:');
    for (const node of snapshot.getNodes_UNSTABLE()) {
      const value = await snapshot.getPromise(node);
      console.debug(node.key, value);
    }
  }, []);

  return <button onClick={onClick}>Dump State</button>
}

function App() {
  const app = document.getElementById('root');
  return (
    <div className="App">
      <RecoilRoot>
        {/* <RecoilizeDebugger root={app}/> */}
        {/* <DebugObserver />
        <DebugButton /> */}
        <App_ />
      </RecoilRoot>
    </div>
  );
}

function App_(){
  return (
  <>
    <SelectMap  />
    <Form />
  </>)
}


export default App;
