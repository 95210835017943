import React, {useEffect, useState } from 'react';
import {MapContainer,TileLayer,Marker,Popup,
        LayersControl,useMap,LayerGroup,ScaleControl,
        } from 'react-leaflet';
import {useRecoilState,useRecoilValue} from 'recoil';
import {locationContext, stations, boundsContext} from './context.js';
import Grid from './grid.js'
import MouseEvents from './events.js'
import Catch from './catch.js'

export default function SelectMap(props){
  
  let [position,setValues] = useRecoilState(locationContext);
  let pos = {lat:position.lat,lng:position.lng+1}
  useEffect(()=>{console.log("Use Effect in SelectMap")
  })
    
    
    return (
        <MapContainer preferCanvas={true} center={position} zoom={8} scrollWheelZoom={true}>
          <MapContext position={position} />
      </MapContainer>
    )
}
function MapContext(props){
  const map = useMap();
  let position = useRecoilValue(locationContext);
  let stats = useRecoilValue(stations);
  // let bounds = useRecoilValue(bounds);
  const [bounds,setBounds] = useRecoilState(boundsContext)
  
  useEffect(()=>{
       map.setView(position,map.getZoom());
    },[position]);
  useEffect(()=>setBounds(map.getBounds()),[map]);
  useEffect(()=>console.log('Stats changed rerender! '+stats),[stats]);
  const opts = {radius:1,opacity:1,color:'darkblue'};

  return (<>
    <MouseEvents position={props.position} 
       />
    <Catch  options={{position:"topleft"}}/> 
   
    <ScaleControl />
    <LayersControl position="bottomleft">
      <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
        <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      {/* <MapConsumer>
        {(map)=>(map.getZoom()>0? */}
      <LayersControl.Overlay name="ERA5 grid" >
        <LayerGroup >
          <Grid bounds={bounds}/>
        </LayerGroup>
      </LayersControl.Overlay>
    </LayersControl>

    <Marker position={props.position}>
    <Popup>
        The location is: {props.position.lat}, {props.position.lng}.
    </Popup>
    </Marker>
    </>
  )
}