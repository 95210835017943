import { Typography } from '@material-ui/core';
import JobTable from './Table.js'
import { useEffect, useState } from 'react';
import {useRecoilValue} from 'recoil'
import {stations,placename} from './context.js'

export default function JobQueue(props){
    
    var stations_ = useRecoilValue(stations)
    var place = useRecoilValue(placename)
    console.log('jobqueue '+stations_.size)

    
    return (
        <div width="100%">
            
            {(stations_ && stations_.size > 0)? 
            <div>
                <Typography variant="h6">List of jobs to be send to the execution queue </Typography>
                <JobTable  name={place} data={stations_} />
            </div>:''}
        </div>
    )
}