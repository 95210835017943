import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { SettingsApplicationsTwoTone } from '@material-ui/icons';
import {stations} from './context.js';
import {useRecoilState} from 'recoil'

export default function JobTable(props){
    const {name} = props;
    const [stats,setStations] = useRecoilState(stations);
    
    let as_array = [...stats];
    let head = Object.keys(as_array[0]);
    console.log("head "+head+' '+as_array.length );

    const deleteLocation = (ev)=> (
      console.log(ev.target.id),
      as_array.splice(ev.target.id,1),
      setStations(new Set(as_array))
    )
    
    return (
        <TableContainer >
          <Table size="small" >
            <TableHead>  
              <TableRow>
                <TableCell key={0}>Place Name</TableCell>
                {head.map(label=>
                  <TableCell key={label} align="right">{label}</TableCell>
                    )}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {as_array.map((el,i) => (
                <TableRow key={i}>
                  <TableCell key={-1} component="th" scope="row">
                    <DeleteOutlineIcon id={i} fontSize="small" onClick={deleteLocation}/> {name} {i}
                  </TableCell>
                  {head.map((key,j) =>
                    <TableCell key={j} align="right">{Number(el[key])===el[key] ? 
                          parseFloat(el[key]).toFixed(3) : 
                          Object.keys(el[key]).filter(o=>el[key][o]).join(', ')}</TableCell>
                    )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
} 