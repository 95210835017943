
import {atom, selector} from 'recoil'

let state = {
    lat:56.4454545,
    lng:8.4,
    elevation:0};

export const openDrawer = atom (
  {key: 'openDrawer',
  default:false}
)
export const boundsContext = atom (
  {key: 'bounds',
  default:{_southWest:{lat:54,lng:7},_northEast:{lat:57,lng:10}}
  }
)

 export const locationContext = atom( // lat lon for marker
     {key: 'locationContext',
      default: state,
    }
 )
 export const jobObject = atom( // Start and end of period for climate data
   {key: 'jobObject',
    default: {start:'2020-12-30',end:'2020-12-31',
      place: "Placename here",
      models:{swat: true,wet:false,swat_hourly:false},
      data_method:'location', 
      version:0.8,
      stations:[]},
  }
)
export const ranges = atom( //contains the set of stations that we want to get climate data for
     {key: 'ranges',
      default: {location:{start:"XXXX-XX-XX",end:"YYYY-YY-YY"},
                time:{start:"--",end:"--"}},
    }
 )
export const selectedRanges = selector(
  {
    'key':"selectedRanges",
    'get':({get}) => {
      var rng = get(ranges);
      var res = {}
      console.log(Object.keys(rng))
      for (var p of Object.keys(rng)){
        console.log(">>>>>>>"+p+"<<<<<<<<<")
        res[p] = {start:rng[p].start.slice(0,10),end:rng[p].end.slice(0,10)}
      }
      return res
    }
  }
)
export const stations = atom( //contains the set of stations that we want to get climate data for
     {key: 'stations',
      default: new Set(),
    }
 )
 export const startTimer = atom( //Chnage value to start countdown timer
   {key: 'startTimer',
    default: false,
  }
)
 export const gridpoints = atom( // Era5 data points in view - list of lat, lon
   {key: 'gridpoints',
    default: [],
  }
)
export const estimatedTime = selector( // calculate the estimated time to retrieve climat data
   {
      'key': "estimatedTime",
      'get': ({get})=> {
            var stats = get(stations);
            var job = get(jobObject);
            var t = Math.ceil((Date.parse(job.end)-Date.parse(job.start))/1000/3600/24/365*10*stats.size);
            console.log("time "+t);
            return t;
         }
   }
)

 export const placename = atom(// name of the location/project
    {key: 'placename',
     default: '',
   }
)

