import {Polyline} from 'react-leaflet'
import {useEffect} from 'react'

export function* range(start,stop,step){
    while (start<=stop){
      yield start;
      start = start + step;
    }
  }
export function quarterDegree(latlon,func){
    return {lat:func(latlon.lat*4)/4,
            lon:func(latlon.lng*4)/4}
  };
  
export default function Grid(props){
  
    let sw = quarterDegree(props.bounds._southWest,Math.ceil)
    let ne = quarterDegree(props.bounds._northEast,Math.floor)
    console.log(ne);
    console.log(props.bounds._northEast);
    let grid = [];
    
    useEffect(()=>{console.log("ERA5Stations")});
    if (true){
      for (let i of range(sw.lat+0.125,ne.lat,0.25)){
        grid.push([[i,props.bounds._southWest.lng],[i,props.bounds._northEast.lng]])
      }
      for (let j of range(sw.lon+0.125,ne.lon,0.25)){
        grid.push([[props.bounds._southWest.lat,j],[props.bounds._northEast.lat,j]])
      }
    }
  
    return (<>
      {grid.map((latlons,i)=>{
        return (<Polyline key={i} pathOptions={{weight:1,color:'purple',opacity:0.5}} 
                    positions={latlons}>
        </Polyline>
        );
      })}
      
    </>
  
    )
  };
  