import {useEffect,useState} from 'react';
import {Typography}  from '@material-ui/core';
import { useRecoilState } from 'recoil';
import {ranges} from './context.js';

export default function QueueSize(props){
    var queueTimer = null

    const getQueueSize =  async () =>{
        const url = `${props.host}/wwt/api/jobs`
        let res = await fetch(url,{method:'GET'}).
            catch(()=>console.log("network error (api)"));
        let jobsQueue = await res.json()
        const len = jobsQueue.length
        
        setSize({...size,queueSize:len,change:size.change+1})
        const url1 = `${props.host}/wwt/api/meta`
        let r = (await fetch(url1,{method:'GET'})).
            catch(()=>console.log("network error (meta)"));
        let ranges = await r.json();
        setRange(ranges);
        clearTimeout(queueTimer)
    }
    let [range,setRange] = useRecoilState(ranges)
    let [size,setSize] = useState({size:()=>getQueueSize(),change:0})
    useEffect(() => getQueueSize(),[])
    useEffect(() => {
        queueTimer = setTimeout(getQueueSize,10000)
      },[size]);

return (<Typography variant="h6">Size of execution queue: {size.queueSize}</Typography> )
}