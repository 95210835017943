import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import createTheme from '@material-ui/core/styles/createTheme'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { AppBar,Toolbar,IconButton, Accordion, 
  Paper, AccordionSummary, FormGroup, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import {Cancel as MenuIcon, Info as InfoIcon} from '@material-ui/icons';
import {locationContext,stations,placename, jobObject, startTimer,selectedRanges} 
        from './context';
import { useRecoilState,useRecoilValue} from 'recoil';
import {Box, Typography } from '@material-ui/core';
import Info from './info.js';
import JobQueue from './jobqueue.js';
import QueueSize from './queuesize.js';
import {host} from './config.js'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import {openDrawer} from './context.js';
import CountDown from './countdown.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import { useContext } from 'react';


function GridContainer(props){
  let direction = props.direction?props.direction:"row";

  let xs = 12/(props.children.length);
  console.log("GridContainer "+xs)
  return(
    <>
    <Grid container justifyContent="center" direction={direction}
    alignItems="center" className={props.classes.root} spacing={5}>
      {React.Children.map(props.children,(child)=>
        <Grid item xs={xs} style={props.style}>
          {child}
        </Grid>
      )}
     
    </Grid>
     
     </>
  )

}
const about_text = "The development if  the ERA5 api is funded by \
    Aarhus university in cooperation with WaterWebTools APS";

function AboutBox(props){

  return <Dialog open={props.open} onClick={()=>props.close(false)}>
    <DialogTitle>About ERA5 api</DialogTitle>
    <DialogContent>
      <Typography>{about_text}</Typography>
    </DialogContent>
  </Dialog>
}
export default function Form(props){
    const models = [{'key':'swat','desc':"SWAT+ Model"},
                    {'key':'swat_hourly','desc':"SWAT+ Model Hourly"},
                    {'key':'wet','desc':"GOTM-WET Model"}]
    const classes = useStyles();
    const theme = createTheme();

    theme.spacing(8); 
    var timer=null;
    const range = useRecoilValue(selectedRanges);
    const [open,setOpen] = useRecoilState(openDrawer);
    const [location,setLocation] = useRecoilState(locationContext);
    const [stats,setStations] = useRecoilState(stations);
    const [place,setPlace] = useRecoilState(placename);
    const [job,setJob] = useRecoilState(jobObject);
    const [startT,setStart] = useRecoilState(startTimer);   
    const [about,setAboutOpen] = useState(false)
    const [state,setState] = useState({queue:[],url:'',id:'',
                email:'redorlik@gmail.com'})
    const [layout,setLayout] = useState({queueSize:0,checked:true,startTimer:false})
    console.log("Funktion form is called ****************")
    
    useEffect(() => {setJob({...job,stations:stats})},[stats]);
    useEffect(() => {setJob({...job,place:place})},[place]);
    // useEffect(() => {stats.forEach(o=>o.models=job.models)
    //                   setStations(new Set([...stats]))},[job.models]);

    const set = (key,context) => {
        return ({target:{value}})=>{
            console.log(key+' '+value)
            if (context === setLocation){
              value = isNaN(value) ? 0.0 :parseFloat(value)
            }
            context(oldstate=>({...oldstate,[key]:value}));
            
          }
    }

    const jobData = () => {
        console.log('jobData '+stats)
        return {...job,stations:[...stats]}
    }


    const  startCreatingDatafiles = async () => {
        console.log('Creating files')
        const url = `${host}/wwt/api/jobs`
        
        const d = jobData() //{jobs:state.queue,email:state.email}
        //d = {...d,data_method: 'location'}
        console.log("!!!! "+ d)
        
        let response = await fetch(url,{
            method: 'POST',
            body: JSON.stringify(d),
            headers:{'Content-Type': 'application/json'}
          })

        let val = await response.json()
        console.log("reason: "+val)
        //setLocation(val)
        setState({...state,url:val.url,id:val.id})
        
        if (response.status !== 200){
            alert("noget gik galt "+response.status)
        }
    }

    
    const onSubmit = async (event) => {
        event.preventDefault(); // Prevent default submission
        console.log('onSubmit')
         
        setStart(true);
        await startCreatingDatafiles();

        try {
          console.log('Your request for data was successfully submitted!');
        } catch (e) {
          alert(`Registration failed! ${e.message}`);
        }
      }
    const onAdd = (event) => {
        setStart(false);
        setStations(new Set(stats.add(location)));
        jobData()
        console.log("ADD to queue button ")
    }
    let clr = "primary";
    return (
<>
<Paper style={{zIndex: 1000,
    position: 'absolute',
    top: 50,
    right: 30}} >
  <Button variant='outlined' onClick={()=>setOpen(true)}>+</Button>
  {/* <Typography >version 0.8</Typography> */}
</Paper>
<Drawer anchor="right" open={open} variant="persistent">
    <Box raised="true" variant="elevation" sx={{width:550}}
      autowidth={"auto"}> 
        <AppBar position="static" color={clr}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                onClick={(e)=>setOpen(false)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="div" sx={{ flexGrow: 0 }}>
                Global Weather data
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                onClick={(e)=>setAboutOpen(true)}
              >
                <InfoIcon />
              </IconButton>
              <AboutBox open={about} close={(e)=>setAboutOpen(false)}/>
            </Grid>
          </Grid>
          
        </Toolbar>
      </AppBar>
      <Box sx={{ p:2 }} >
        <Typography variant="body1" >
          First choose the wanted model(s).
          Then choose either a point by clicking on the map and clicking on the button 'ADD POINT'
          or select an area with the lasso tool. When the stations wanted are selected click on the 
          'REQUEST DATA' button. 
        </Typography>
        <Divider sx={{marginTop:20}}/>
      <form onSubmit={onSubmit} className={classes.root}
       autoComplete="off">
         <FormGroup>
        <GridContainer classes={classes}>
        {models.map(m =>(
        <FormControlLabel 
            control={<Checkbox checked={job.models[m.key]} 
            onChange={(ev)=>setJob({...job,models:{...job.models,
                    [m.key]:ev.target.checked}})} 
            color="primary"
            value={m.key} name={m.key} key={m.key} />}
            label={m['desc']}/>
          ))}
        </GridContainer>
        </FormGroup>  
        <GridContainer classes={classes}>
          <TextField id="name" type="text"  required label="Name" value={place}
            helperText="Name of location" 
            onChange={({target:{value}})=>setPlace(value)} 
            variant="outlined" key={1} />
          <TextField id="email" type="email" required label="E-mail" value={job.email} 
            onChange={set('email',setJob)} variant="outlined"
            helperText="Email for notification" key={2} />
          
        </GridContainer>
        <GridContainer classes={classes} >
                <Typography  variant="body2" component="div"  sx={{ flexGrow: 0}}>
                  Land date range <br></br>
                {range.location.start.slice(0,10)} - {range.location.end}
                </Typography>
                <Typography variant="body2" component="div" sx={{ flexGrow: 0 }}>
                  Land and sea date range<br></br> {range.time.start} - {range.time.end}
                </Typography>
          </GridContainer>
         <GridContainer classes={classes} >
         <TextField id="start" type="date" required label="Start date" value={job.start} 
            onChange={set('start',setJob)} variant="outlined"
            helperText="Start date of data set" key={3} />
          <TextField id="end" type="date"  required label="End date" value={job.end} 
            onChange={set('end',setJob)} variant="outlined"
            helperText="End date" key={4} />
          
          
        </GridContainer>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            >
            <Typography variant='body1'>Point interface</Typography>
          </AccordionSummary>
        <Divider />
        <Typography  variant="body1">
              Pick points on the map and press the button. Or use the lasso tool to select many stations at once.
            </Typography>
        <GridContainer classes={classes}>
          
            <TextField id="lat" type="text" required label="Latitude" 
            value={parseFloat(location.lat).toFixed(3)} 
            inputProps={{ inputMode: 'numeric', pattern: '[0-9.-]*' }}
            onChange={set('lat',setLocation)} variant="outlined"
            helperText="Latitude of cursor" key={5}/>

            <TextField id="lng" type="text" required 
          inputProps={{ inputMode: 'numeric', pattern: '[0-9.-]*' }}
          label="Longitude" value={parseFloat(location.lng).toFixed(3)} 
            onChange={set('lng',setLocation)} variant="outlined" 
            helperText="Longitude of cursor" key={6}/>

            <TextField id="elevation" type="text" required 
          inputProps={{ inputMode: 'numeric', pattern: '[0-9.-]*' }}
          label="Elevation" value={parseFloat(location.elevation)} 
            onChange={set('elevation',setLocation)} variant="outlined" 
            helperText="Elevation of position" key={7}/>
            <GridContainer classes={classes} style={{"padding-left":"0px",
            "padding-top":"0px","max-width":"100%","color":"grey"}}
              direction="column">
              <FormControlLabel 
              control={<Checkbox checked={job.data_method==='time'} 
              onChange={(ev)=>setJob({...job,data_method:
                job.data_method==='location'?'time':'location'})} 
              color="primary"
              />}
              labelPlacement ='top'
              label={<Typography variant="body2">
                Check if point is in the sea. Requests will be slower
                </Typography>}
                />
              
              <Button variant="contained" color="primary" 
                size="small" onClick={onAdd} key={8}>
                Add Point
              </Button>
            </GridContainer>
        </GridContainer>
        </Accordion>
    </form>
    
      <JobQueue sx={{width:"100%"}} queue={stats}  name={job.place} host={host} />
      
      <QueueSize host={host} />
      <Divider />
      <GridContainer classes={classes}>
        <CountDown   initialTime={0} startTimer={layout.startTimer} />
        <Typography variant="body1">Job ID: {state.id} </Typography>
        <div>
          <Typography variant="body2">
            The following link is first valid when the dataset is complete. You can copy the link for later use.
        </Typography>
        <a className="nolink" href={state.url} >Link to data</a></div> 
        
      </GridContainer>
        
        
        <Button variant="contained" size="small" color="secondary" 
          type="submit" disabled={stats.size===0} onClick={onSubmit}>Request data</Button>
    
      </Box>
  </Box> 
  <Divider />    
</Drawer>  
</>  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    
    flexGrow: 1,
  },
  span: {
      margin: '10px',
  },
  side_by_side:{
    display: 'inline-flex'
  },
  Paper: {
      
      display: 'flex',
      flexWrap: 'wrap',
       '& > *': {
        margin: theme.spacing(1),
        //width:  '25ch',
        
       },
    }, 
        
})
);
