import {useState} from 'react'
import {useMapEvents} from 'react-leaflet'
import {stations,jobObject, locationContext,
    boundsContext} from './context.js'
import {useRecoilState,useRecoilValue} from 'recoil'
import { openDrawer } from './context.js'

export default function MouseEvents(props) {
    const [open,setOpen] = useRecoilState(openDrawer)
    const [layers,setLayers] = useState([])
    const [lasso, setLasso] = useState(false)
    const [stat,setStations] = useRecoilState(stations)
    const [bounds,setBounds] = useRecoilState(boundsContext)
    const [location,setValues] = useRecoilState(locationContext)
    const opts = {opacity:1.,fillOpacity:1.,color:'darkblue'} 
    const invisible = {opacity:0.,fillOpacity:0.}
    let localSet = new Set();

    const getElevation = async (latlng_set) =>{
      let latlng = [];
      //Check antal punkter - skal være mindre end rate limit
      if (latlng_set.size>100) {
        alert("Too many points. Must be below 100 (was "+latlng_set.size+")")
      }
      latlng_set.forEach((o)=> latlng.push(String(o._latlng.lat)+","+String(o._latlng.lng)));

      let pos =latlng.join("|");
      let url = `${window.location.origin.toString()}`;
      url = url.split(":").slice(0,2).join(":")
      let req = url+":8090/wwt/api/elevation?locations="+pos+"&dataset=aster30m";
      console.log("Get elevation "+req)
      let resp = await fetch(req);
      let res = await resp.json();
      console.log("Elevations",res)
      latlng_set.forEach(ll=>res.results.forEach(r=>{
        if ((ll._latlng.lat===r.location.lat)&&
            (ll._latlng.lng===r.location.lng)){

            if (r.elevation === null||r.elevation === 0){
              ll.obj.setStyle(invisible)
              setStations(latlng_set.delete(ll))
            }else{ 
              ll.elevation=r.elevation
            }
        }
      }))
      const b = new Set()
      latlng_set.forEach(o=> b.add({...o._latlng,
        elevation:o.elevation}))
      setStations(b);
      console.log("!!!!!!!!!!! Stations "+stat.size)
    }
    const getData = (ev)=>{
      if (layers.layers!==undefined){
        layers.layers.filter(o => o._point !== undefined).forEach(obj=>(
        obj.setStyle(invisible)
      ))}

      setLasso(true)
      ev.layers.filter(o => o._point !== undefined).forEach((obj,i) => (
          console.log("Index "+i),
                localSet.add({...obj,
                  obj:obj,
                  models:obj.models,
                  }),
                obj.setStyle(opts)
                ))
      setOpen(true);
      getElevation(localSet);
      setLayers(ev);
    }
    const map = useMapEvents({
        'lasso.finished':getData,
        click: (loc) => {
          if(lasso){
            setLasso(false)
          }
          else{
          setOpen(true);
          setValues(old => ({...old,'lat':loc.latlng.lat,'lng':loc.latlng.lng,
              'elevation':0}))
        }
        },
        zoomend:(ev) =>{
          setBounds(map.getBounds())},
        move: (ev) =>{
          setBounds(map.getBounds());
          },
        locationfound: (location) => {
          console.log('location found:', location)
        },
      })
      return null
    }
  